import { left, right } from './ErrorHandler/Either';
import { logger } from './Logger';
export default {
  getItem: function getItem(key) {
    try {
      if (true) {
        var _localStorage;

        var value = (_localStorage = localStorage) === null || _localStorage === void 0 ? void 0 : _localStorage.getItem("editor-".concat(key));

        if (value) {
          return right(value);
        }

        return left(new Error("No value found for key: ".concat(key)));
      }
    } catch (ex) {
      left(new Error("Error getting item ".concat(key)));
    }

    return left(new Error('safeGetItem: no window'));
  },
  setItem: function setItem(key, value) {
    try {
      setTimeout(function () {
        if (true) {
          var _localStorage2;

          (_localStorage2 = localStorage) === null || _localStorage2 === void 0 ? void 0 : _localStorage2.setItem("editor-".concat(key), value);
        }
      }, 0);
      return true;
    } catch (ex) {
      return Error(ex);
    }
  },
  removeItem: function removeItem(key) {
    setTimeout(function () {
      try {
        if (true) {
          var _localStorage3;

          (_localStorage3 = localStorage) === null || _localStorage3 === void 0 ? void 0 : _localStorage3.removeItem(key);
        }
      } catch (ex) {
        logger.warn(ex);
      }
    }, 0);
    return true;
  }
};